import styles from './TopBar.module.sass';

function TopBar() {
  return (
    <div className={styles.topbar}>

    </div>
  );
}

export default TopBar;
