import styles from "./App.sass";
import Home from "./components/home/Home";

function App() {
  return (
    <div className={styles.app}>
      <Home/>
    </div>
  );
}

export default App;
