import styles from './OrderPanel.module.sass';

function OrderPanel() {
  return (
    <div className={styles.orderPanel}>

    </div>
  );
}

export default OrderPanel;
