import styles from './History.module.sass';

function History() {
  return (
    <div className={styles.history}>

    </div>
  );
}

export default History;
