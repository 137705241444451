import styles from './Home.module.sass';
import OrderPanel from "./orderPanel/OrderPanel";
import Dashboard from "./dashboard/Dashboard";
import TopBar from "./topbar/TopBar";

function Home() {
  return (
    <div className={styles.home}>
        <TopBar/>
        <Dashboard/>
        <OrderPanel/>
    </div>
  );
}

export default Home;
