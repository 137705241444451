import styles from './Dashboard.module.sass';
import Graph from "./graph/Graph";
import History from "./history/History";
import Portfolio from "./portfolio/Portfolio";

function Dashboard() {
  return (
    <div className={styles.dashboard}>
      <Graph/>
      <History/>
      <Portfolio/>
    </div>
  );
}

export default Dashboard;
