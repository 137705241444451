import styles from './Graph.module.sass';

function Graph() {
  return (
    <div className={styles.graph}>

    </div>
  );
}

export default Graph;
