import styles from './Portfolio.module.sass';

function Portfolio() {
  return (
    <div className={styles.portfolio}>

    </div>
  );
}

export default Portfolio;
